<template>
  <search-form :items="items" @fnSearch="handleFnSearch" />
  <grid
    class="mt-10"
    :columns="columns"
    :code="$route.path"
    :url="url"
    :search="search"
    :scroll="{ y: gridHeight }"
  >
    <template #operation="{ record }">
      <operation :options="options" :record="record" />
    </template>
  </grid>
</template>

<script>
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import portraitApi from '@/api/portrait';
import integralApi from '@/api/integral';
import memberApi from '@/api/member';

export default {
  name: 'portraitApi',
  components: {
    SearchForm,
    Grid,
    Operation,
  },
  data() {
    return {
      items: [
        { key: 'name', label: '姓名' },
        { key: 'filterMaturity', label: '成熟度' },
        { key: 'region', label: '地区' },
        { key: 'country', label: '国家' },
        { key: 'company', label: '公司' },
        { key: 'tagName', label: '标签' },
        { key: 'jobTitle', label: '职位' },
        { key: 'industryLv1', label: '行业' },
        { key: 'isActivate', label: '激活' },
      ],
      columns: [
        { dataIndex: 'name', title: '姓名' },
        { dataIndex: 'maturity', title: '成熟度', width: 100 },
        { dataIndex: 'liveness', title: '活跃度', width: 100 },
        { dataIndex: 'jobTitle', title: '职位' },
        { dataIndex: 'company', title: '公司' },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 50,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'view',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: 'portrait:view',
          fnClick: ({ id }) => this.$router.push(`/vipcenter/portrait/${id}`),
        },
      ],
      url: portraitApi.pageUrl,
      gridHeight: document.body.clientHeight - 461,
      search: {},
      areaDeptOptions: [],
      isActivateOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '未激活',
        },
        {
          value: '1',
          label: '待激活',
        },
        {
          value: '2',
          label: '已激活',
        },
      ],
      JobTitleOptions: [],
      searchForm: {
        isActivate: '',
        region: '',
        memberLevel: '',
        startCreateTime: '',
        endCreateTime: '',
        tagName: '',
        name: '',
        country: '',
        company: '',
        jobTitle: '',
        industryLv1: '',
        filterUser: [],
        startMaturity: '',
        endMaturity: '',
        startLiveness: '',
        endLiveness: '',
      },
      filterMaturity: '0',
      tableData: [],
      current: 1,
      size: 10,
      total: 0,
      loading: false,
      multipleSelection: [],
      dataPicVal: '',
      industryLv1Options: [],
      areaOptions: [],
      tagNameOptions: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
        {
          value: '选项3',
          label: '蚵仔煎',
        },
        {
          value: '选项4',
          label: '龙须面',
        },
        {
          value: '选项5',
          label: '北京烤鸭',
        },
      ],
      IntegralRecordVisible: false, // 积分记录对话框状态
      IntegralRecordParams: {
        current: 1,
        size: 10,
        total: 0,
        contactCode: '',
        loading: true,
      },
      IntegralRecordData: {},
      filterMaturityOptions: [
        {
          value: '1',
          label: '高',
        },
        {
          value: '2',
          label: '中',
        },
        {
          value: '3',
          label: '低',
        },
      ],
      filterLiveness: '0',
      filterLivenessOptions: [
        {
          value: '1',
          label: '高',
        },
        {
          value: '2',
          label: '中',
        },
        {
          value: '3',
          label: '低',
        },
      ],
    };
  },
  watch: {
    filterMaturity(newValue) {
      switch (newValue) {
        case '0':
          return Object.assign(this.searchForm, {
            startMaturity: '',
            endMaturity: '',
          });
        case '1':
          return Object.assign(this.searchForm, {
            startMaturity: '80',
            endMaturity: '',
          });
        case '2':
          return Object.assign(this.searchForm, {
            startMaturity: '40',
            endMaturity: '79',
          });
        case '3':
          return Object.assign(this.searchForm, {
            startMaturity: '0',
            endMaturity: '39',
          });
      }
    },
    filterLiveness(newValue) {
      switch (newValue) {
        case '0':
          return Object.assign(this.searchForm, {
            startLiveness: '',
            endLiveness: '',
          });
        case '1':
          return Object.assign(this.searchForm, {
            startLiveness: '5',
            endLiveness: '100',
          });
        case '2':
          return Object.assign(this.searchForm, {
            startLiveness: '4',
            endLiveness: '5',
          });
        case '3':
          return Object.assign(this.searchForm, {
            startLiveness: '1',
            endLiveness: '3',
          });
      }
    },
  },
  async mounted() {
    //await this.getAreaOptions();
    //this.getList();
    this.getTagName();
    // const areaDeptRes = await integralApi.getAreaDeptOptions();
    // this.areaDeptOptions = Object.values(
    //   areaDeptRes.data.AREA_DEPT
    // ).map(item => ({ value: item }));
    // const INDUSTRYRes = await integralApi.getIndustryLv1Options();
    // this.industryLv1Options = Object.values(
    //   INDUSTRYRes.data.INDUSTRY
    // ).map(item => ({ value: item }));
    // const JobTitleRes = await integralApi.getJobTitleOptions();
    // this.JobTitleOptions = Object.values(
    //   JobTitleRes.data['Job Title']
    // ).map(item => ({ value: item }));
  },
  methods: {
    handleFnSearch(value) {
      this.search = value;
    },

    async getList(isSearch) {
      if (isSearch) this.current = 1;
      this.loading = true;
      const params = Object.assign({}, this.searchForm, {
        country: this.areaChEntransformation(this.searchForm.country, 'En'),
        tagName: this.searchForm.tagName.join(','),
      });
      params.size = this.size;
      params.current = this.current;
      const res = await portraitApi.getPortrayalList(params);
      this.loading = false;
      if (!res) return;
      this.tableData = res.data.records;
      this.size = parseInt(res.data.size);
      this.total = parseInt(res.data.total);
    },
    areaChEntransformation(area, targetLanguage) {
      if (targetLanguage === 'Ch') {
        const areaObj = this.areaOptions.find(areaObj => areaObj.en === area);
        return areaObj ? areaObj.value : area;
      }

      if (targetLanguage === 'En') {
        const areaObj = this.areaOptions.find(
          areaObj => areaObj.value === area
        );
        return areaObj ? areaObj.en : area;
      }
    },
    async getTagName() {
      const res = await memberApi.getTagName();
      this.tagNameOptions = res.map(item => ({ value: item }));
    },
    // 获取地区选择列表
    async getAreaOptions() {
      const res = await integralApi.getAreaOptions();
      const areas = Object.keys(res.data.AREA);
      this.areaOptions = areas.map(key => ({
        en: key,
        value: res.data.AREA[key],
      }));
    },
  },
};
</script>
